/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle, Image, GCalendar, Fullmap, FullmapWrap, FullmapCover, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-z9o0ih pb--60 pt--60" name={"m80kz8v3pw"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/61db6c53383440798598d33f315849d4_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box lh--12" content={"<br><br><br><br><br><br>."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--style2 --full pb--30 pt--10" name={"uvod"} style={{"backgroundColor":"rgba(32, 32, 32, 1)"}} parallax={false} fullscreen={true}>
          
          <ColumnWrap className="column__flex el--3 pt--40 flex--top" columns={"3"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
              
              <Text className="text-box text-box--invert" content={"Máte rádi hory? Jezdíte na kole nebo na koloběžce? Lezete po skalách? Sjíždíte řeky?&nbsp; Vyžíváte se v zimních sportech nebo preferujete plavání, surfování či paddleboarding? Máte rádi osamocená místa daleko na severu, nebo pulsující rušné kempy ve Středomoří? "}>
              </Text>

              <Text className="text-box text-box--invert" content={"Proměňte své sny v realitu.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 ff--2" href={"#rezervace"} content={"Půjč si mě"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center mt--0 pb--0 pl--0 pr--0 pt--0" style={{"marginBottom":31}}>
              
              <Subtitle className="subtitle-box ff--1 w--300 subtitle-box--invert" style={{"maxWidth":369}} content={"<span style=\"color: rgb(243, 239, 239);\">půjčovna obytného vozu</span><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18557/dcc7a469c6ff401a8c365b1bb3427929_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18557/dcc7a469c6ff401a8c365b1bb3427929_s=350x_.png 350w, https://cdn.swbpg.com/t/18557/dcc7a469c6ff401a8c365b1bb3427929_s=660x_.png 660w, https://cdn.swbpg.com/t/18557/dcc7a469c6ff401a8c365b1bb3427929_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--right pb--0 pl--0 pr--0 pt--0">
              
              <Text className="text-box text-box--center text-box--invert" style={{"maxWidth":363}} content={"Propadněte kouzlu karavaningu a z<br>&nbsp;nezávislého cestování se stane váš nový životní styl."}>
              </Text>

              <Text className="text-box text-box--invert" content={"Karavaning je svoboda – buďte sami sebou."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"i56v1rlgt5"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/12838000296d4ba0856eceda3585f119_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/12838000296d4ba0856eceda3585f119_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/12838000296d4ba0856eceda3585f119_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/dcf8bf6f239c41b88749169609dc05ed_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/dcf8bf6f239c41b88749169609dc05ed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/dcf8bf6f239c41b88749169609dc05ed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/dcf8bf6f239c41b88749169609dc05ed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/dcf8bf6f239c41b88749169609dc05ed_s=1400x_.jpg 1400w"} position={{"x":"-44.230769230769226%","y":"-2.153846153846154%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/9788915a53c14c66a4d7c8fec42cc125_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"gridColumnGap":"1em"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/97d73f0eb01b4830abfe04b674e6aa81_s=860x_.gif"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/97d73f0eb01b4830abfe04b674e6aa81_s=350x_.gif 350w, https://cdn.swbpg.com/t/18557/97d73f0eb01b4830abfe04b674e6aa81_s=660x_.gif 660w, https://cdn.swbpg.com/t/18557/97d73f0eb01b4830abfe04b674e6aa81_s=860x_.gif 860w, https://cdn.swbpg.com/t/18557/97d73f0eb01b4830abfe04b674e6aa81_s=1400x_.gif 1400w"} position={{"x":"-35.5685131195335%","y":"-13.119533527696781%"}}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":230}} content={"Polointegrovaný vůz pro 4 osoby"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":290}} content={"Obytný vůz pro 4 osoby modelová řada 2021\n\n-MC4 339 -délka 649 cm\n\n-topení Truma 4 plyn\n\n-motorizace Fiat Ducato 140PS\n\n-polstrování Aria\n\n-topení Truma 4 + CP Plus"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/e21ad799b0954bdab090f5b2e010ad6e_s=2000x_.jpg 2000w"} position={{"x":"-80.22448979591837%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Obytné vozy McLouis"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":290}} content={"Italská značka McLouis byla vytvořena v roce 1999. I přesto, že se jedná o poměrně mladou značku, jejich vozy jsou revoluční díky funkčnosti, designu, technologii, tažnosti, výkonu a spolehlivosti. McLouis je odezvou na stále rostoucí trh. Filozofií této značky je najít vždy nová řešení a nabízet alternativy. Proto je každý vůz McLouis souhrnem nápadů v pohybu, což nabízí uživatelům takové možnosti, díky kterým dosáhnou svých snů o cestování."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/1f6bf7534094487a874fd82a60e44b78_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":250}} content={"Prostorný a chytrý<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":290}} content={"Všestranné modulární prostory přesně podle vašich představ.\n\nNa palubě McLouis je vnitřní prostor optimalizován tak, aby poskytoval maximální funkčnost: úložné přihrádky jsou navrženy tak, aby se přizpůsobily vašim cestovatelským a prázdninovým potřebám.\n\nDno postele lze zvednout tak, aby bylo možné flexibilně manipulovat s objemem nákladu podle vašich potřeb."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-8ruvtc pb--50 pt--50" name={"cenik"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0988cf9c5a0c44699c6a35d0b70e2d2e_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--80 pt--80" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--40" name={"obrazek"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"text-decoration: underline;\"><a href=\"/technicke-specifikace\">Technicke&nbsp;specifikace</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" style={{"gridGap":"1em"}} columns={"4"}>
            
            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Technické specifikace"}>
              </Subtitle>

              <Text className="text-box" content={"Fiat Ducato<br>Celková délka: &nbsp;6490 mm<br>Šířka: 2350 mm<br>Výška: 2950 mm<br>Max. hmotnost: 3500kg<br>Počet míst k sezení: 4<br>Počet míst k spaní: 4<br>Obsah motoru: 2,3 cm3-103kW/3600ot.min.<br>Palivo: Diesel(nafta)<br>Kategorie vozidla: M1<br>Rok výroby: 2021<br>Emise: Euro 6<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Obývák<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Obývací prostor nabízí pohodlí a potěšení z moderního obytného prostoru, kde můžete trávit čas s rodinou a přáteli. Jídelní sedačky, měkké a útulné, jsou ideální pro relaxaci na konci dne, při oblíbeném jídle nebo\nsledování filmu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Kuchyně<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Vaření na palubě &nbsp;McLouis 339 je zábava. Kuchyně je dokonale vybavena: velké úložné prostory, dřez z nerezové oceli, varná deska se dvěmi hořáky, digestoř, zjednodušují vaření, dělají ho příjemným a relaxačním."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Ložnice<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Blažený spánek, nebeské probuzení.<br>Spánek je zásadní pro to, abyste každému dni dovolené čelili plní energie.\n\nVšechny postele nabízejí dostatek prostoru pro pohodlný odpočinek a lamelové rošty ideální stabilitu a ventilaci."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Sprcha"}>
              </Subtitle>

              <Text className="text-box" content={"Velký sprchový kout - &nbsp;integrovaný &nbsp;- je ideálním místem pro zahájení dne se správným množstvím energie nebo pro jeho ukončení relaxací."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Koupelna<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Luxusní soukromí pro vaši pohodu.\nVelmi prostorná toaleta je díky zrcadlu a osvětlení ideálním místem pro péči o sebe. Nechybí přirozené světlo a výměna vzduchu: všechny koupelny řady 300 mají otevírací okno. Police, úložné prostory, nástěnné jednotky a dveře vám umožní mít vždy po ruce vše, co potřebujete pro vaši pohodu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Úložné prostory<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Spací část je vybavena prostornými nástěnnými skříňkami a skříněmi pro uložení oblečení a doplňků. Zvedací základny umožňují přístup k dalším úložným prostorům, které obsahují vše, co potřebujete pro svou dovolenou, aniž byste se čehokoli vzdali."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--30 pl--20 pr--20 pt--30" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box" content={"Garáž"}>
              </Subtitle>

              <Text className="text-box" content={"Velká garáž s nosností až 300 kg pohodlně přepravuje kola, zavazadla a objemné předměty. Praktické použití díky dvěma symetrickým dveřím standardně s otevíráním o 180 stupňů, vybaveným gumovým těsněním odolným proti stříkající vodě a mrazu, odolávající vlhkosti a nízkým teplotám. Maxi-garáž, osvětlená a vytápěná, může být použita v jakémkoli stavu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--20" name={"fotogalerie"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"gridGap":"0 1em"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/9beee9ce8c1b46f986736a461f6e48e3_s=2000x_.jpg 2000w"} position={{"x":"-34.45481049562682%","y":"-0.2915451895043732%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/8785a9b2ba8a40e5ad91d8d5c8e8c12a_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/1a87d8fb40ef4c5ebe03693f45d4713f_s=2000x_.jpg 2000w"} position={{"x":"-54.0728862973761%","y":"-32.291545189504376%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/eaad3746aad449eeade1da3443b6beaf_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/eaad3746aad449eeade1da3443b6beaf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/eaad3746aad449eeade1da3443b6beaf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/eaad3746aad449eeade1da3443b6beaf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/eaad3746aad449eeade1da3443b6beaf_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-42.85714285714286%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18557/584d4beb75e9407786ab7493abb6430f_s=2000x_.jpg 2000w"} position={{"x":"-21.830903790087465%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18557/620a6148792a4307a980c66960bc9067_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18557/620a6148792a4307a980c66960bc9067_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18557/620a6148792a4307a980c66960bc9067_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18557/620a6148792a4307a980c66960bc9067_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18557/620a6148792a4307a980c66960bc9067_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--40" name={"cenik"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Ceník"}>
              </Subtitle>

              <Text className="text-box ff--2" style={{"maxWidth":660}} content={"Uvedená cena je za den zapůjčení.<br><br>V případě zájmu o zapůjčení na kratší dobu nás prosím kontaktujte."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":660}} content={"<a href=\"/podminky-zapujceni\">Podmínky zapůjčení.</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--30" name={"cenik-2"} layout={"l22"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="pb--08 pt--08" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box ff--1 fs--18" content={"1.4. - 18.6. &nbsp;(minimální doba zapůjčení 3 dny) &nbsp;<br>"}>
              </Subtitle>

              <Text className="text-box text-box--right ff--1 fs--18 w--600" style={{"color":"var(--color-main-1)","maxWidth":382}} content={"3600 Kč (včetně DPH 21%)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box ff--1 fs--18" content={"19.6. - 12.9. (minimální doba zapůjčení 7 dní)&nbsp;<br>"}>
              </Subtitle>

              <Text className="text-box text-box--right ff--1 fs--18 w--600" style={{"color":"var(--color-main-1)"}} content={"4300 Kč (včetně DPH 21%)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08" style={{"border":"3px solid #f7f7f7"}}>
              
              <Subtitle className="subtitle-box ff--1 fs--18" content={"13.9. - 30.11. (minimální doba zapůjčení 3 dny)&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--right ff--1 fs--18 w--600" style={{"color":"var(--color-main-1)"}} content={"3600 Kč (včetně DPH 21%)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border3 pb--30 pt--30" anim={null} name={"kalendar"} border={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <GCalendar src={"https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=NG1rYXJhdmFuQGdtYWlsLmNvbQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=Y3MuY3plY2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%230B8043"}>
              </GCalendar>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"euyy8bn9h3"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"Ulice, 666 00 Město"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover place={"Rychvald, březová 2089"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--30" name={"rezervace"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="mt--16">
              
              <Subtitle className="subtitle-box ff--2" content={"Rezervace"}>
              </Subtitle>

              <Text className="text-box ff--2" style={{"maxWidth":740}} content={"Rezervujte si vůz pro svou dovolenou včas. <br><br>Po vyplnění rezervačního formuláře vyčkejte prosím na potvrzení mailem.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":""}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-13d24pr pb--50 pt--80" name={"kontakt-2"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18557/0b550c930ba44c4da00ae18851a5d906_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert" content={"<br>"}>
              </Subtitle>

              <Title className="title-box ff--1 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">4M KARAVAN</span><br>"}>
              </Title>

              <Text className="text-box text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Březová 2089, 735 32 &nbsp;Rychvald</span><br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 605 256 295<br>+420 604 276 179<br></span>"}>
              </Text>

              <Text className="text-box fs--20 w--500 text-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">4mkaravan@gmail.com</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

              <Text className="text-box fs--18 w--500 mt--0" content={"saywebpage.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}